<template>
  <div
    class="mx-auto mb-12 flex w-full max-w-screen-xl flex-col items-center justify-center gap-2 bg-opacity-0 px-5 md:my-16 lg:my-24">
    <div class="mb-8 grid items-end gap-4 lg:mb-24 lg:grid-cols-12 lg:gap-12">
      <div class="col-span-6">
        <h2 class="mb-6 text-2xl md:mb-10 md:text-5xl">
          {{ data.listItemSection?.title }}
        </h2>
        <div class="mb-6 flex flex-col items-start justify-center space-y-4">
          <template v-for="item in data.listItemSection?.listItems" :key="item.id">
            <SharedListItem :data="item" :map="mapLink" />
          </template>
        </div>
        <SharedSimpleImage :data="data.listItemSection?.image" class="max-w-xl" />
      </div>
      <SharedSimpleImage :data="data.simpleImage" class="col-span-6 mt-8 max-w-xl lg:mt-0" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Map } from "~/types/strapi/components/block/interfaces/Map";

defineProps<{
  data: Map;
}>();

const mapLink = "https://maps.app.goo.gl/fiKqRxDwWgjzM8XdA";
</script>

<style lang="scss" scoped></style>
